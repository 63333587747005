export const RideFilters = {
	statuses: "statuses",
	dayOfWeeks: "dayOfWeeks",
	id: "id",
	tripNumberShortCode: "tripNumberShortCode",
	lineTitle: "lineTitle",
	lineIds: "lineIds",
	fromDateTime: "fromDateTime",
	toDateTime: "toDateTime",
};

export const OrderFilters = {
	orderId: "orderId",
	customerEmail: "customerEmail",
	voucher: "voucher",
	passengerFirstName: "passengerFirstName",
	passengerLastName: "passengerLastName",
	passengerPhone: "passengerPhone",
	languageName: "languageName",
	frontendSourceLabel: "frontendSourceLabel",
	type: "type",
	orderValue: "orderValue",
	paymentHash: "paymentHash",
	pspReference: "pspReference",
	currency: "currency",
	statuses: "statuses",
	lineTitle: "lineTitle",
	lineIds: "lineIds",
	dateSearchCondition: "dateSearchCondition",
	fromDate: "fromDate",
	toDate: "toDate",
	transactionId: "paypalAuthorizationId",
};
