/* component with a calendar date picker for rides form */
/* TODO: add parameters to search with before and after condition */

import { Calendar, Input, Grid, GridCol } from "@flixbus/honeycomb-react";
import { Icon, IconCalendar } from "@flixbus/honeycomb-icons-react";
import { CreatedAtMinDate, OrderFilters } from "../../constants";
import { useEffect, useState } from "react";
import { isValidDate } from "../../utils";
import Moment from "moment";
import * as Constants from "../../constants";

export const DatePickerInput = ({
	filterName,
	label = "Created At",
	handleDateSelect,
	handleDateChange,
}) => {
	const today = new Date();
	const [selectedDate, setSelectedDate] = useState(null);
	const [showCalendar, setShowCalendar] = useState(false);
	const [startDate, setStartDate] = useState(CreatedAtMinDate);
	const [endDate, setEndDate] = useState(today);
	const [createdFromDate, setCreatedFromDate] = useState(null);
	const [createdToDate, setCreatedToDate] = useState(null);

	useEffect(() => {
		try {
			// pass event to parent when second date is selected
			if (
				createdToDate &&
				isValidDate(createdFromDate) &&
				isValidDate(createdToDate)
			) {
				console.log("pass event");
				handleDateSelect(createdFromDate, createdToDate);
			}
		} catch (error) {
			alert("Error in DatePickerInput useEffect");
			console.error(error);
		}
	}, [createdToDate]);

	const handleInputValue = (selectedFromDate, selectedToDate) => {
		if (selectedFromDate === null) {
			return;
		}

		// when there is no selectedToDate or they are equal, return the selectedFromDate
		if (
			selectedToDate === null ||
			Moment(selectedToDate).isSame(selectedFromDate)
		) {
			return Moment(selectedFromDate).format(Constants.DateFullYear);
		} else if (selectedToDate !== null && selectedFromDate !== null) {
			return `${Moment(selectedFromDate).format(
				Constants.DateFullYear
			)}  -  ${Moment(selectedToDate).format(Constants.DateFullYear)}`;
		}
	};

	return (
		<div data-testid="date-picker-input">
			<Input
				label={label}
				placeholder="Select a date"
				iconLeft={<Icon InlineIcon={IconCalendar} />}
				id="departure-date"
				data-testid={filterName}
				name={filterName}
				onFocus={() => setShowCalendar(true)}
				value={handleInputValue(createdFromDate, createdToDate)}
				readOnly={true}
				onChange={handleDateChange}
				aria-live="polite"
			/>
			<div className="CalendarRelativeWrapper">
				<div className="CalendarWrapper" data-testid="calendar-wrapper">
					<Calendar
						id="date-picker"
						aria-controls="departure-date return-date"
						horizontal
						startDate={startDate}
						endDate={endDate}
						startSelected={createdFromDate}
						endSelected={createdToDate}
						defaultMonth={new Date()}
						appearance={"compact"}
						selected={selectedDate}
						hidden={!showCalendar}
						handleSelect={(date) => {
							if (createdFromDate === null) {
								setCreatedFromDate(date);
								setStartDate(date);
								return;
							}
							if (createdToDate === null) {
								setCreatedToDate(date);
								setShowCalendar(false);
								setStartDate(CreatedAtMinDate);
								return;
							}
							if (createdToDate !== null && createdFromDate !== null) {
								setCreatedToDate(null);
								setCreatedFromDate(date);
								setStartDate(date);
								return;
							}
						}}
					/>
				</div>
			</div>
		</div>
	);
};
