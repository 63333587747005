/* if there is date parameter was selected in the calendar send it as an obj with "at" key */
import Moment from "moment";
import { isValidFormattedDate } from "./validator";
import * as Constants from "../constants";

// TODO: refactor "at" key and add "to" date for date range search
export const formatDateSearchCondition = (fromDate, toDate) => {
	if (!fromDate && !toDate) {
		return null;
	}

	let dateSearchCondition;

	// if both dates were selected
	if (fromDate && toDate) {
		let from = Moment(fromDate, Constants.DateFullYear).format(Constants.DateSearchFormat);
		let to = Moment(toDate, Constants.DateFullYear).format(Constants.DateSearchFormat);

		if (isValidFormattedDate(from) && isValidFormattedDate(to)) {
			dateSearchCondition = {
				fromDate: from,
				toDate: to,
			};
		} else {
			dateSearchCondition = null;
		}
	}

	// if only one date was selected
	if (fromDate && !toDate || !fromDate && toDate) {
		let date = fromDate || toDate;
		date = Moment(date, Constants.DateFullYear).format(Constants.DateSearchFormat);

		if (isValidFormattedDate(date)) {
			dateSearchCondition = {
				at: date,
			};
		} else {
			dateSearchCondition = null;
		}
	}


	return dateSearchCondition;
};
