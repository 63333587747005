import { CalendarDateRegEx } from "../constants";

export const isValidDate = (d) => {
	if (d === undefined || d === null) return false;
	return d instanceof Date && !isNaN(d);
};

export const isValidFormattedDate = (date) => {
	return CalendarDateRegEx.test(date);
};

export const isValidOrderId = (orderId) => {
	if (orderId === undefined || orderId === null || orderId.length === 0)
		return null;
	return orderId.length < 25 && /^\d+$/.test(orderId);
};
